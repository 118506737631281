require('./bootstrap');

//CLASS ACTIVE NAV -------------------------------------------------------------
$('.navbar-nav a[href="' + location.href + '"]').addClass('active');
$('.navbar-nav a[href="' + location.href.slice(0, -1) + '"]').addClass('active');

//HEIGHT FOOTER
$('.wrapper').css("margin-bottom", $('footer').height()+"px");

$(window).on('resize', function () {
	$('.wrapper').css("margin-bottom", $('footer').height()+"px");
});

//SMOOTH SCROLL ----------------------------------------------------------------
$('a[href^="#contact"]').click(function () {
	$('.navbar-collapse').collapse('hide');
	contact = $('#contact').offset().top + $('#contact').height() - $('nav').height();
	$('html, body').animate({ scrollTop: contact }, 'slow');
	return false;
});

//TARGET _BLANK ----------------------------------------------------------------
url = $('meta[name=landing]').attr("content");
$("a").not("[href^='" + url + "']").not("[href^='tel']").attr("target", "_blank").attr("rel", "noopener dofollow");


//CAPTCHA
$(window).bind("load", function () {
	$.getScript("https://www.google.com/recaptcha/api.js?render=6Ld8cdMUAAAAAMN4qK4is83KlyNp_GuYOhiM2nJM", function( data, textStatus, jqxhr ) {
		grecaptcha.ready(function() {
			grecaptcha.execute("6Ld8cdMUAAAAAMN4qK4is83KlyNp_GuYOhiM2nJM", {action: 'homepage'}).then(function(token) {
				document.getElementById('g-recaptcha-response').value=token;
			});
		});
	});
});

$(".carousel.lazy").bind('slide.bs.carousel', function (e) {
	var lazy;
	lazy = $(e.relatedTarget).find("img[data-src1]");
	lazy.attr("src", lazy.data('src1'));
	lazy.removeAttr("data-src1");
});

$(window).bind("load", function () {
	$("#MainCarousel .carousel-item a").attr('href', "#carousel-modal")
	$("#RoomsCarousel .carousel-item a").attr('href', "#RoomsCarousel-modal")
});

Konami = require('konami')
//EASTER EGGS
var easter_egg = new Konami(function() {
	$('body').css({
		"-webkit-transform": "rotateY(180deg)",
		"-ms-transform": "rotateY(180deg)",
		"-moz-transform": "rotateY(180deg)",
		"transform": "rotateY(180deg)",

		"-webkit-transition" :"1s ease-in-out",
		"-moz-transition" :"1s ease-in-out",
		"-o-transition" :"1s ease-in-out",
		"transition" :"1s ease-in-out"
	})
});
